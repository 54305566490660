const BASE_API_URL = "https://stories-api-vhcl.onrender.com"
const DEV_API_URL = "http://localhost:5001"
const BASE_WEB_URL = "https://endlessrealms.ai/"
const DEV_WEB_URL = "http://localhost:3000"

export const WEB_URL = (process.env.REACT_APP_ENV === 'production') ? BASE_WEB_URL : DEV_WEB_URL;
export const API_URL = (process.env.REACT_APP_ENV === 'production') ? BASE_API_URL : DEV_API_URL;

export const LINKS = {
    TERMS_OF_SERVICE: 'https://www.aibraingames.io/#:~:text=%E2%80%A2-,Terms%20of%20Use,-%E2%80%A2',
    PRIVACY_POLICY: 'https://www.aibraingames.io/#:~:text=%E2%80%A2-,Privacy%20Policy,-Puzzle%20games%20created'
}